@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 9));
    transform: translateX(calc(-250px * 9));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 9));
    transform: translateX(calc(-250px * 9));
  }
}

.slider {

  box-shadow: 0 10px 20px -5px rgba(10, 10, 10, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  max-width: 1028px;
}

.slider::before,
.slider::after {
  background: linear-gradient(to right, rgb(10, 10, 10) 0%, rgba(10, 10, 10, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  display: flex;
  -webkit-animation: scroll 30s linear infinite;
  animation: scroll 30s linear infinite;
}

.slider .slide {
  flex-shrink: 0;
  width: 176px; /* Adjust based on your content */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

body{
  background-color: #090909;
  overflow-x: hidden;
}

.gradient-text {
  background: linear-gradient(to right, #b04900, #c99d3d); /* Green gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-background {
  background: linear-gradient(to right, #b04300, #c98f3d); /* Green gradient colors */
}

.pricing-gradient{
  background: rgb(185,104,0);
  background: linear-gradient(0deg, rgba(185,104,0,0.6488970588235294) 13%, rgba(255,222,17,0) 100%);
}

